import { Base } from './base.js';

export class Social extends Base {
    constructor() {
        super();
        this.socialLoginMessage = document.querySelector('.socialloginmsg');
        
        this.emailInput = document.getElementById('emailInput');
        this.passwordInput = document.getElementById('passwordInput');

        this.passwordCheckButton = document.getElementById('checkLoginButton');
        this.confirmButton = document.querySelector('.check-login-button');
        this.agbConfirm = document.getElementById('agbInput');
        this.commentsConfirm = document.getElementById('commentsInput');
        this.confirmError = document.getElementById('missingAgb');
    }

    googleLogin() {
        let ref = CONFIG.redirectUrl + "&reason=" + CONFIG.registrationReason 
        
        //Google extended access?
        let sentParams = this.getUrlParams();
        ref += ("gaa_at" in sentParams) && CONFIG.initialForm == "googleExternalLink" ? "&socialType=googleExtendedAccess" : "&socialType=google";

        //Audio App?
        ref += CONFIG.environment.isAudioApp ? "&app=audio" : "";

        this.hideErrorMessages();
        let sType = ("gaa_at" in sentParams) && CONFIG.initialForm == "googleExternalLink" ? "googleExtendedAccess" : "google";
        let formData = {
            referer: ref,
            socialType: sType,
        };

        let request = new Request('POST', '/api/get/socialloginurl');

        request.data = formData;
        request.call(response => {
            if (response.status == 'ok') {
                window.location.href = response.data.url;
            } else {
                if (this.socialLoginMessage) {
                    this.socialLoginMessage.innerHTML = "Bei der Verbindung zum Login bei Google ist ein Fehler aufgetreten. Die korrekte URL konnte nicht ermittelt werden. Bitte versuchen Sie es erneut oder wenden Sie sich an unseren Leserservice.";
                    this.showBlock('.socialloginmsg');
                }
            }
        }, () => {
            if (this.socialLoginMessage) {
                this.socialLoginMessage.innerHTML = this.errorCode.unknownError;
                this.showBlock('.socialloginmsg');
            }
        });
    }

    appleLogin() {
        this.buttonSpinner(".apple-button");
        let ref = CONFIG.redirectUrl + "&reason=" + CONFIG.registrationReason + "&socialType=apple";
        this.hideErrorMessages();
        let sType = "apple";
        let formData = {
            referer: ref,
            socialType: sType,
        };
        let request = new Request('POST', '/api/get/socialloginurl');

        request.data = formData;
        request.call(response => {
            if (response.status == 'ok') {
                this.buttonSpinner(".apple-button", false);
                window.location.href = response.data.url;

            } else {

                if (this.socialLoginMessage) {
                    this.socialLoginMessage.innerHTML = "Bei der Verbindung zum Login bei Apple ist ein Fehler aufgetreten. Die korrekte URL konnte nicht ermittelt werden. Bitte versuchen Sie es erneut oder wenden Sie sich an unseren Leserservice.";
                    this.showBlock('.socialloginmsg');
                    console.log("Error: ", response);
                }
                this.buttonSpinner(".apple-button", false);
            }
        }, () => {
            if (this.socialLoginMessage) {
                this.socialLoginMessage.innerHTML = this.errorCode.unknownError;
                this.showBlock('.socialloginmsg');
                this.buttonSpinner(".apple-button", false);
            }
        });
    }

    googleExternalLink() {
        this.pushHistoryState("googleExternalLink");
    }
    appleExternalLink() {
        this.pushHistoryState("appleExternalLink");
    }

    login() {
        this.pushHistoryState("socialLogin");
    }

    loginConfirm() {
        this.pushHistoryState("socialLoginConfirm");

        if (this.emailInput) {
            this.emailInput.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }

        if (this.passwordInput) {
            this.passwordInput.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }

        if (this.passwordCheckButton) {
            this.passwordCheckButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.socialLoginConfirm();
            });
        }
    }

    loginConsensConfirm() {
        this.pushHistoryState("socialLoginConsensConfirm");

        if (this.agbConfirm) {
            this.agbConfirm.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }

        if (this.commentsConfirm) {
            this.commentsConfirm.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }
        
        if (this.confirmButton) {
            this.confirmButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.socialConsens();
            });
        }
    }

    socialLoginConfirm() {
        if (!this.emailInput.value) {
            console.log("E-Mail nicht übermittelt oder Reload der Seite erfolgt. Leite zum Start zurück.");
            window.location.href = this.baseUrl + "/" + window.location.search;
        } else {
            this.emailInput.addEventListener("focus", () => {
                this.hideErrorMessages();
            });
        }

        if (this.passwordInput) {
            this.passwordInput.addEventListener("focus", () => {
                this.hideErrorMessages();
            });
        }

        this.buttonSpinner(this.passwordCheckButton);
        if(this.passwordInput.value != "") {
            this.hideErrorMessages();
                    
            let sentParams = this.getUrlParams();
            let sType = ("socialType" in sentParams) ? sentParams.socialType : "google";
            // get Snowplow cookies
            let spnozmhn_spId = this.findCookieName("nozmhn_spid");
            let conversion_Id = CONFIG.registrationReason == "Paywall" ? Cookies.get("_pc_conversion_id", false) : false;
            let formData = {
                form: 'socialProfileMergeConfirm',
                password: this.passwordInput.value,
                email: this.emailInput.value,
                linkingState: CONFIG.socialLoginGoogleParams.linkingState ? CONFIG.socialLoginGoogleParams.linkingState : '',
                referrer: this.redirectUrl,
                socialType: sType,
                spnozmhnIdName: spnozmhn_spId,
                spnozmhnIdValue: Cookies.get(spnozmhn_spId, false),
                sp: Cookies.get("sp", false),
                remoteIp: CONFIG.client.ip,
                userAgent: CONFIG.client.userAgent,
                conversionId: conversion_Id,
            };
   
            let request = new Request('POST', '/api/confirm/sociallogin');
            request.data = formData;
            request.call(response => {
                if (response.status == 'ok') {
                    if (response.data.accessToken) {
                        if (CONFIG.environment.isLocal) {
                            this.redirectUrl = this.redirectUrl + "&globalSessionId=" + response.data.accessToken;
                        }

                        let redirect = false;
                        if (response.data.tac) {
                            this.tacCookie = response.data.tac;
                            redirect = this.redirectUrl;
                        }
    
                        this.utpCookie = response.data.accessToken;
    
                        if (this.missingName) {
                            document.location.href = this.missingNameRoute;
                        } else {
                            if (redirect) {
                                if(Cookies.get("_pc_conversion_id", false)) {
                                    Cookies.remove("_pc_conversion_id", false);
                                }
                                if(!CONFIG.environment.isAudioApp) {
                                    document.location.href = redirect;    
                                } else {
                                    document.location.href = "audioapp-shz://open_app?access_token=" + this.utpCookie;
                                }
                            } else {
                                this.updateTacCookie();
                            }
                        }
                    } else {
                        this.buttonSpinner(".check-login-button", false);
                    }
                } else {
                    let message = "Bei der Überprüfung Ihres Passwortes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
                    if (response.code == 403) {
                        message = "Das eingegebene Passwort ist nicht korrekt. Bitte versuchen Sie es erneut.";
                    }
                    console.error("Error: ", response);
                    document.querySelector('.form-error-password').innerHTML = message;
                    this.showBlock('.form-error-password');
                    this.buttonSpinner(".check-login-button", false);
                }
            }, () => {
                this.buttonSpinner(".check-login-button", false);
            });
        }
    }

    socialConsens() {
        this.buttonSpinner(".check-login-button");
    
        if( (! this.agbConfirm.checked) || (! this.commentsConfirm.checked) ) {
            this.hideBlock(this.confirmError);
            this.buttonSpinner(".check-login-button", false);
        } else {
            this.hideErrorMessages();

            let sentParams = this.getUrlParams();
            let sType = ("socialType" in sentParams) ? sentParams.socialType : "google";
            let spnozmhn_spId = this.findCookieName("nozmhn_spid");
            let conversion_Id = CONFIG.registrationReason == "Paywall" ? Cookies.get("_pc_conversion_id", false) : false;

            let formData = {
                form: 'formConsensConfirm',
                additionalInputState: CONFIG.socialLoginGoogleParams.additionalInputState ? CONFIG.socialLoginGoogleParams.additionalInputState : '',
                regReason: CONFIG.registrationReason,
                referrer: this.redirectUrl,
                socialType: sType,
                spnozmhnIdName: spnozmhn_spId,
                spnozmhnIdValue: Cookies.get(spnozmhn_spId, false),
                sp: Cookies.get("sp", false),
                remoteIp: CONFIG.client.ip,
                userAgent: CONFIG.client.userAgent,
                conversionId: conversion_Id,
            };

            let request = new Request('POST', '/api/confirm/socialConsens');
            request.data = formData;
            request.call(response => {
                if (response.status == 'ok') {
                    if (response.data.accessToken) {
                        if (CONFIG.environment.isLocal) {
                            this.redirectUrl = redirectUrl + "&globalSessionId=" + response.data.accessToken;
                        }

                        let redirect = false;
                        if (response.data.tac) {
                            this.tacCookie = response.data.tac;
                            redirect = this.redirectUrl;
                        }

                        this.utpCookie = response.data.accessToken;

                        if (this.missingName) {
                            document.location.href= this.missingNameRoute;
                        } else {
                            if (redirect) {
                                if(Cookies.get("_pc_conversion_id", false)) {
                                    Cookies.remove("_pc_conversion_id", false);
                                }
                                if(!CONFIG.environment.isAudioApp) {
                                    document.location.href = redirect;    
                                } else {
                                    document.location.href = "audioapp-shz://open_app?access_token=" + this.utpCookie;
                                }
                            } else {
                                this.updateTacCookie();
                            }
                        }
                    } else {
                        this.buttonSpinner(".check-login-button", false);
                    }
                } else {
                    console.error("Error: ", response);
                    document.querySelector('.form-error-confirm').innerHTML = "Bei der Einrichtung Ihres Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
                    this.showBlock('.form-error-confirm');
                    this.buttonSpinner(".check-login-button", false);
                }
            }, () => {
                this.buttonSpinner(".check-login-button", false);
            });
        }
    }
}