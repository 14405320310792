import Request from './request.js';
import Cookies from 'js-cookie';

import { Login } from './login.js';
import { Logout } from './logout.js';
import { Social } from './social.js';
import { PersonalData } from './personalData.js';
import { ResetPassword } from './resetPassword.js';
import { RegisterUser } from './registerUser.js';

window.SSO = false;
window.Request = Request;
window.Cookies = Cookies;

document.addEventListener("DOMContentLoaded", () => {
    switch (CONFIG.initialForm) {
        case 'login': window.SSO = new Login(); break;
        case 'logout': window.SSO = new Logout(); break;
        case 'resetPassword': window.SSO = new ResetPassword(); break;
        case 'addPersonalData': window.SSO = new PersonalData(); break;
        case 'register': window.SSO = new RegisterUser(); break;
        case 'googleExternalLink': {
            window.SSO = new Social();
            window.SSO.googleExternalLink();
            window.SSO.googleLogin();
        } break;
        case 'appleExternalLink': {
            window.SSO = new Social();
            window.SSO.appleExternalLink();
            window.SSO.appleLogin();
        } break;
        // bestehender Social Login
        case 'socialLogin': {
            window.SSO = new Social();
            window.SSO.login();
        } break;
        // bestehendes Konto - erstmalig Google Login
        case 'socialLoginConfirm': {
            window.SSO = new Social();
            window.SSO.loginConfirm();
        } break;
        // Register with Google
        case 'socialLoginConsensConfirm': {
            window.SSO = new Social();
            window.SSO.loginConsensConfirm();
        }
    }
});
